import Axios, { AxiosRequestConfig } from 'axios';

import { IoTCoreAdminSystemInfo } from '@/providers/envConfig';
import storage from '@/utils/storage';
import { baseUrlRequestInterceptor } from './axiosAdminUtils';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (!config.headers) {
    config.headers = {};
  }

  const envInfo = IoTCoreAdminSystemInfo.get();

  config.headers['ClearBlade-SystemKey'] = envInfo.systemKey;
  config.headers['ClearBlade-SystemSecret'] = envInfo.systemSecret;

  if (token) {
    config.headers['ClearBlade-UserToken'] = token;
  }

  config.headers.Accept = 'application/json';
  return config;
}

export const axiosAdminUserAccount = Axios.create({});
axiosAdminUserAccount.interceptors.request.use(authRequestInterceptor);
axiosAdminUserAccount.interceptors.request.use(baseUrlRequestInterceptor);
