import { axiosAdminNoAuth } from '@/lib/axiosAdminNoAuth';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { useMutation } from '@tanstack/react-query';

export type OIDCRegisterFormDataDTO = {
  provider_name: string;
  first_name: string;
  last_name: string;
};

const fetchOIDCRegEndpoint = async (data: OIDCRegisterFormDataDTO) => {
  const requestURL = `${location.protocol}//${location.hostname}/api/v/1/user/oidc/reg`;
  const adminSystemInfo = IoTCoreAdminSystemInfo.get();
  const resp = await axiosAdminNoAuth.post(requestURL, data, {
    headers: {
      'ClearBlade-SystemKey': `${adminSystemInfo.systemKey}`,
      'ClearBlade-SystemSecret': `${adminSystemInfo.systemSecret}`,
    },
  });

  window.location.href = resp.data.url;
};

export const useFetchOIDCRegEndpoint = () => {
  return useMutation(fetchOIDCRegEndpoint);
};
