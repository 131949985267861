import { axiosAdminNoAuth } from '@/lib/axiosAdminNoAuth';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export type GetResetRequestParams = {
  pending_reset_id?: string;
};

export const getResetRequest = async (data: GetResetRequestParams): Promise<unknown> => {
  const resp = await axiosAdminNoAuth.get(
    `/api/v/4/webhook/execute/${IoTCoreAdminSystemInfo.get().systemKey}/getResetRequest?pending_reset_id=${
      data.pending_reset_id
    }`,
  );
  return resp.data;
};

export const getResetRequestQueryKeys = {
  all: (params: { email: string; baseUrl: string }) => [{ scope: 'getResetRequest', params }] as const,
};

export const useGetResetRequest = <TContext>(
  options?: UseMutationOptions<unknown, unknown, GetResetRequestParams, TContext>,
) => {
  return useMutation((payload: GetResetRequestParams) => getResetRequest(payload), options);
};
