import { axiosAdminUserAccount } from '@/lib/axiosAdminUserAccount';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { useQuery } from '@tanstack/react-query';
import { ProjectArea } from '../types';

const getProjectAreas = async (): Promise<{ projectAreas: ProjectArea[] }> => {
  const resp = await axiosAdminUserAccount.post<{ projectAreas: ProjectArea[] }>(
    `/api/v/1/code/${IoTCoreAdminSystemInfo.get().systemKey}/getProjectAreas`,
  );
  return resp.data;
};

export const projectAreasQueryKeys = {
  all: () => [{ scope: 'projectAreas' }] as const,
};

export const useProjectAreasQuery = (options?: { onError: () => void }) => {
  return useQuery(projectAreasQueryKeys.all(), getProjectAreas, options);
};
