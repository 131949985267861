import { axiosAdminUserAccount } from '@/lib/axiosAdminUserAccount';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

interface RegistryCredentials {
  systemKey: string;
  serviceAccountToken: string;
  url: string;
}

const getRegistryCredentials = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<ReturnType<typeof registryCredentialsQueryKeys.all>>): Promise<RegistryCredentials> => {
  const resp = await axiosAdminUserAccount.post<RegistryCredentials>(
    `/api/v/1/code/${IoTCoreAdminSystemInfo.get().systemKey}/getRegistryCredentials`,
    params,
  );
  return resp.data;
};

const registryCredentialsQueryKeys = {
  all: (params: { project: string; region: string; registry: string }) =>
    [{ scope: 'registryCredentials', params }] as const,
};

export const useRegistryCredentialsQuery = (
  params: { project: string; region: string; registry: string },
  options?: { onSuccess: (data: RegistryCredentials) => void; onError: () => void },
) => {
  return useQuery(registryCredentialsQueryKeys.all(params), getRegistryCredentials, { ...options });
};
