import { useAllUserPermissions } from '@/api';
import ErrorBoundary from '@/components/ErrorBoundary';
import { MainIotCoreAppBar } from '@/components/IoTCoreAppBar';
import { authPathResolver } from '@/features/auth';
import { useAuth } from '@/lib/auth';
import { lazyImport } from '@/utils/lazyImport';
import { CircularProgress, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AccountSettingsRoutes } from './accountSettings';
import { ProtectedRouteIndex } from './config';

const { RegistriesRoutes } = lazyImport(() => import('@/features/registries'), 'RegistriesRoutes');
const { ProjectsRoutes } = lazyImport(() => import('@/features/projects'), 'ProjectsRoutes');

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const userPerms = useAllUserPermissions();

  if (auth.isLoggingIn || auth.isRegistering || userPerms.isLoading) {
    return (
      <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Box>
          <Typography>Authenticating...</Typography>
          <LinearProgress />
        </Box>
      </Box>
    );
  }
  if (!auth.user) {
    return (
      <Navigate to={{ pathname: `${authPathResolver.login()}`, search: `?redirect=${location.pathname}` }} replace />
    );
  }
  return (
    <Suspense
      fallback={
        <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

export const protectedRoutes = [
  {
    path: `${ProtectedRouteIndex}/*`,
    element: (
      <ProtectedRoute>
        <ErrorBoundary>
          <MainIotCoreAppBar />
          <Suspense
            fallback={
              <Box width="100%" height="calc(100vh - 48px)" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            }
          >
            <ProjectsRoutes />
            <RegistriesRoutes />
            <AccountSettingsRoutes />
          </Suspense>
        </ErrorBoundary>
      </ProtectedRoute>
    ),
  },
];
