import { Alert, Box, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export class IoTCoreAdminSystemInfo {
  private static info: {
    URL: string;
    systemKey: string;
    systemSecret: string;
    regionList: string[];
  };

  static get() {
    return IoTCoreAdminSystemInfo.info;
  }

  static set(envInfo: EnvInfo) {
    IoTCoreAdminSystemInfo.info = {
      /**
       * note: ADMIN_URL will be undefined in production. in that case, set the value to an empty string
       * so that all requests to the admin system are made on the same domain that the UI is hosted on
       */
      URL: typeof envInfo.ADMIN_URL === 'undefined' ? location.origin : envInfo.ADMIN_URL,
      systemKey: envInfo.ADMIN_SYSTEM_KEY,
      systemSecret: envInfo.ADMIN_SYSTEM_SECRET,
      regionList: envInfo.ADMIN_REGION_LIST.split(','),
    };
  }
}

export const EnvConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const envConfigQuery = useEnvConfigQuery();

  if (envConfigQuery.isLoading) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (envConfigQuery.isError) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Alert severity="error">Failed to fetch environment config. Please contact system administrator</Alert>
      </Box>
    );
  }

  if (!envConfigQuery.isSuccess) {
    return null;
  }

  return <>{children}</>;
};

const envConfigQueryKeys = {
  all: () => [{ scope: 'envConfig' }] as const,
};

function useEnvConfigQuery() {
  return useQuery(envConfigQueryKeys.all(), getEnvConfig, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      IoTCoreAdminSystemInfo.set(data);
    },
  });
}

async function getEnvConfig() {
  const resp = await axios.get<EnvInfo>(`/iotcore/admin`);
  return resp.data;
}
