import { axiosAdminUserAccount } from '@/lib/axiosAdminUserAccount';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ProjectArea } from '../types';

interface Payload {
  project_area: string;
}

const deleteProjectArea = async (payload: Payload): Promise<ProjectArea> => {
  const resp = await axiosAdminUserAccount.post<ProjectArea>(
    `/api/v/1/code/${IoTCoreAdminSystemInfo.get().systemKey}/projectsDelete`,
    payload,
  );
  return resp.data;
};

export const useProjectAreasDelete = <TContext>(
  options?: UseMutationOptions<ProjectArea, unknown, Payload, TContext>,
) => {
  return useMutation((payload: Payload) => deleteProjectArea(payload), options);
};
