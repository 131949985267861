import { TextHookForm } from '@/components/HookFormFields';
import { InputLabel } from '@/components/InputLabel';
import { InternalLink } from '@/components/InternalLink';
import { useAuth } from '@/lib/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Divider, Grid, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as z from 'zod';
import { authPathResolver } from '../routes';
import { LoginErrorTypes } from '../types';

const schema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn, loginIsError, loginError } = useAuth();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(schema),
  });
  const [searchParams] = useSearchParams();

  return (
    <div style={{ width: '100%' }}>
      {loginIsError && <LoginError error={loginError} />}
      <form
        autoComplete="off"
        onSubmit={handleSubmit(async (values) => {
          try {
            await login(values);
            onSuccess();
          } catch (_) {
            // need to catch error to avoid getting an 'unhandled promise reject' error. no need to do anything with error here since it's handled by 'loginIsError'
            return;
          }
        })}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InputLabel label="Email" />
            <TextHookForm autoFocus fullWidth control={control} name="email" size="small" />
          </Grid>
          <Grid item xs={12}>
            <InputLabel label="Password" />
            <TextHookForm fullWidth type="password" control={control} name="password" size="small" />
            <InternalLink to={authPathResolver.forgotPassword()}>
              <Typography variant="body2">Forgot password?</Typography>
            </InternalLink>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton fullWidth loading={isLoggingIn} variant="contained" type="submit">
              Log in
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <Box mt={4}>
        {/* commenting our ProviderButtons for now until we're ready to turn on OIDC for IoT Core */}
        {/* <ProviderButtons action={'Log in'} /> */}
      </Box>
      <Box mt={4}>
        <Divider />
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <Box>
          <Typography>
            Don&apos;t have an account?{' '}
            <InternalLink
              to={{
                pathname: authPathResolver.register(),
                // pass any search params along (e.g., x-gcp-marketplace-token)
                search: `?${searchParams.toString()}`,
              }}
            >
              Sign up
            </InternalLink>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

function getErrorType(error: unknown): { type: LoginErrorTypes } {
  if (error && typeof error === 'object') {
    const errorData = (error as AxiosError).response?.data;
    if (errorData && JSON.stringify(errorData).includes('password')) {
      return { type: 'invalid_user_name_or_password' };
    }
    if (errorData && JSON.stringify(errorData).includes('user not found')) {
      return { type: 'user_not_found' };
    }
  }
  return { type: 'unknown' };
}

export function LoginError({ error }: { error: unknown }) {
  const errorType = getErrorType(error);
  return (
    <Alert sx={{ mb: 2 }} severity="error">
      {(errorType.type === 'invalid_user_name_or_password' || errorType.type === 'user_not_found') &&
        'Username or password is incorrect'}
      {errorType.type === 'unknown' && 'Unknown error'}
    </Alert>
  );
}
