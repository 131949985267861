import { useUserHavePermForResource } from '@/api';
import { TextHookForm } from '@/components/HookFormFields';
import { registriesPathResolvers } from '@/features/registries';
import { useIsMobile } from '@/hooks';
import { useToggle } from '@/hooks/useToggle';
import { parseError } from '@/utils/parseError';
import storage from '@/utils/storage';
import { zodResolver } from '@hookform/resolvers/zod';
import { Add, ArrowDropDown, Check, CreateNewFolder, Delete, Edit, Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useProjectAreasDelete } from '../api/deleteProjectArea';
import { projectAreasQueryKeys, useProjectAreasQuery } from '../api/getProjectAreas';
import { projectPathResolvers } from '../routes';

interface ProjectSelectProps {
  selectedProject: string | null;
  onProjectSelected?: (projectArea: string) => void;
}

interface ProjectSelectModalProps extends ProjectSelectProps {
  open: boolean;
  closeModal?: () => void;
  showDialogActions?: boolean;
}

export const ProjectSelectModalButton: React.FC<ProjectSelectProps> = ({ selectedProject, onProjectSelected }) => {
  const { isOpen: open, close: closeModal, open: openModal } = useToggle(false);

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          textAlign: 'left',
          textTransform: 'inherit',
          color: 'lightWhiteDarkBlack',
          borderColor: 'lightWhiteDarkBlack',
          '&:hover': {
            borderColor: 'lightWhiteDarkBlack',
          },
          mr: 3,
        }}
        size="small"
        onClick={() => openModal()}
        endIcon={<ArrowDropDown />}
      >
        <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden', width: '100%' }}>
          {selectedProject ? selectedProject : 'Select project'}
        </Typography>
      </Button>
      <ProjectSelectModal
        open={open}
        closeModal={closeModal}
        selectedProject={selectedProject}
        onProjectSelected={onProjectSelected}
      />
    </>
  );
};

export const ProjectSelectModal: React.FC<ProjectSelectModalProps> = ({
  open,
  closeModal,
  selectedProject,
  onProjectSelected,
  showDialogActions = true,
}) => {
  const isMobile = useIsMobile();

  const CreateButton = (
    <Button
      component={RouterLink}
      to={projectPathResolvers.projectNew()}
      onClick={() => !!closeModal && closeModal()}
      startIcon={<Add />}
      variant="outlined"
      size="small"
    >
      {isMobile ? 'Create' : 'Create project'}
    </Button>
  );

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      PaperProps={{ sx: { width: '756px', maxWidth: '80vw', height: 'min(80%, 600px)' } }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Select a project</Typography>
          {CreateButton}
        </Box>
      </DialogTitle>
      <DialogContent>
        <ProjectSelect
          selectedProject={selectedProject}
          onProjectSelected={(projectArea: string) => {
            !!onProjectSelected && onProjectSelected(projectArea);
            storage.setSelectedProject(projectArea);
            !!closeModal && closeModal();
          }}
          CreateButton={CreateButton}
        />
      </DialogContent>
      {showDialogActions && (
        <DialogActions>
          <Button variant="text" onClick={closeModal}>
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const ProjectSelect: React.FC<ProjectSelectProps & { CreateButton: JSX.Element }> = ({
  selectedProject,
  onProjectSelected,
  CreateButton,
}) => {
  const [search, setSearch] = useState<string>('');
  const projectAreasQuery = useProjectAreasQuery();
  const { isOpen: isDeleteConfirmOpen, open: openDeleteConfirm, close: closeDeleteConfirm } = useToggle();
  const [projectToDelete, setProjectToDelete] = useState<string>();

  if (projectAreasQuery.isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (projectAreasQuery.isError) {
    return <Alert severity="error">Error loading projects</Alert>;
  }

  return (
    <>
      <Box sx={{ '> * + *': { mt: 2 } }}>
        <TextField
          sx={{ mt: 2 }}
          name="search"
          label="Search"
          size="small"
          fullWidth
          InputProps={{ startAdornment: <Search /> }}
          onChange={(e) => setSearch(e.target.value)}
        />
        {projectAreasQuery.data.projectAreas.length === 0 && (
          <Grid
            container
            spacing={3}
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            sx={{ mt: 3 }}
          >
            <Grid container item direction="column" justifyContent="center" alignItems="center">
              <Grid item>
                <CreateNewFolder sx={{ color: 'text.disabled', fontSize: 70 }} />
              </Grid>
              <Grid item>
                <Typography variant="h6">You have not added any projects yet</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary">Create a project to get started</Typography>
              </Grid>
            </Grid>
            <Grid item>{CreateButton}</Grid>
          </Grid>
        )}
        <Table size="small">
          <TableBody>
            {projectAreasQuery.data.projectAreas
              .filter((project) => project.project_area.includes(search))
              .map(({ project_area }) => (
                <TableRow key={project_area}>
                  <TableCell>
                    {selectedProject === project_area && <Check sx={{ mx: 2 }} />}
                    {selectedProject !== project_area && <Box sx={{ mx: 2, height: 24, width: 24 }} />}
                  </TableCell>
                  <ProjectLinkCell project_area={project_area} />
                  <ProjectActionsCell
                    project_area={project_area}
                    onProjectSelected={onProjectSelected}
                    openDeleteConfirm={openDeleteConfirm}
                    setProjectToDelete={setProjectToDelete}
                  />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      {projectToDelete && (
        <DeleteProject open={isDeleteConfirmOpen} onClose={closeDeleteConfirm} project={projectToDelete} />
      )}
    </>
  );
};

const ProjectLinkCell = ({
  project_area,
  onProjectSelected,
}: {
  project_area: string;
  onProjectSelected?: (project: string) => void;
}) => {
  const navigate = useNavigate();
  const canListRegistries = useUserHavePermForResource({
    perm: 'cloudiot.registries.list',
    resources: [
      {
        type: 'project',
        project: project_area,
      },
    ],
  });

  if (!canListRegistries) return <TableCell sx={{ width: '100%' }}>{project_area}</TableCell>;
  return (
    <TableCell sx={{ width: '100%' }}>
      <Link
        sx={{ textTransform: 'inherit' }}
        component={Button}
        onClick={() => {
          onProjectSelected && onProjectSelected(project_area);
          navigate(registriesPathResolvers.index({ project: project_area }));
        }}
      >
        {project_area}
      </Link>
    </TableCell>
  );
};

const ProjectActionsCell = ({
  project_area,
  onProjectSelected,
  openDeleteConfirm,
  setProjectToDelete,
}: {
  project_area: string;
  onProjectSelected?: (project: string) => void;
  openDeleteConfirm: () => void;
  setProjectToDelete: (project: string) => void;
}) => {
  const navigate = useNavigate();
  const canDeleteProject = useUserHavePermForResource({
    perm: 'cloudiot.projects.delete',
    resources: [{ type: 'project', project: project_area }],
  });
  const canUpdateProject = useUserHavePermForResource({
    perm: 'cloudiot.projects.update',
    resources: [{ type: 'project', project: project_area }],
  });

  return (
    <TableCell>
      <Box display="flex" justifyContent="center">
        <IconButton
          disabled={!canUpdateProject}
          onClick={() => {
            onProjectSelected && onProjectSelected(project_area);
            navigate(projectPathResolvers.projectEdit({ projectName: project_area }));
          }}
          sx={{ mx: 1 }}
          aria-label="edit"
        >
          <Edit />
        </IconButton>
        <IconButton
          disabled={!canDeleteProject}
          onClick={() => {
            openDeleteConfirm();
            setProjectToDelete(project_area);
          }}
          sx={{ mx: 1 }}
          aria-label="delete"
        >
          <Delete />
        </IconButton>
      </Box>
    </TableCell>
  );
};

const createDeleteSchema = (project: string) =>
  z.object({
    project: z
      .string()
      .min(1, 'Required')
      .refine((val) => val === project, `Enter ${project} to confirm`),
  });
function DeleteProject({ onClose, open, project }: { open: boolean; onClose: () => void; project: string }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      project: '',
    },
    resolver: zodResolver(createDeleteSchema(project)),
  });
  const myOnClose = () => {
    onClose();
    reset(); // reset form if user cancels
  };
  const deleteProjectArea = useProjectAreasDelete({
    onSuccess() {
      queryClient.invalidateQueries(projectAreasQueryKeys.all());
      myOnClose();
    },
  });

  return (
    <>
      <Dialog open={open} onClose={myOnClose}>
        <form
          onSubmit={handleSubmit(() => {
            if (location.search.includes(project)) {
              navigate(projectPathResolvers.projects());
            }
            deleteProjectArea.mutate({
              project_area: project,
            });
          })}
        >
          <DialogTitle>Delete project?</DialogTitle>
          <DialogContent>
            <Alert sx={{ my: 2 }} severity="warning">
              This operation cannot be undone.
            </Alert>
            <Typography variant="body2">
              Deleting a project will permanently remove the project and all of its registries.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Confirm you want to delete this project by entering the name: </Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {project}
              </Typography>
              <TextHookForm
                label="Project name"
                required
                sx={{ mt: 1 }}
                size="small"
                control={control}
                name="project"
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={myOnClose} type="button" variant="text" color="inherit">
              Cancel
            </Button>
            <LoadingButton loading={deleteProjectArea.isLoading} type="submit" variant="contained" color="primary">
              Delete
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={deleteProjectArea.isError}>
        <DialogTitle>Failed to delete project</DialogTitle>
        <DialogContent>{parseError(deleteProjectArea.error)}</DialogContent>
        <DialogActions>
          <Button onClick={deleteProjectArea.reset}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
