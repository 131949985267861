import { axiosAdminNoAuth } from '@/lib/axiosAdminNoAuth';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { UserRegistrationResponse } from '../types';

export type RegisterCredentialsDTO = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  x_gcp_marketplace_token?: string;
  platform_url?: string;
  platform_reg_key?: string;
};

export const registerWithEmailAndPassword = async (data: RegisterCredentialsDTO): Promise<UserRegistrationResponse> => {
  const resp = await axiosAdminNoAuth.post(
    `/api/v/4/webhook/execute/${IoTCoreAdminSystemInfo.get().systemKey}/createClearBladeIoTCoreUser`,
    data,
  );
  return resp.data;
};
