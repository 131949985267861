import { Resource } from '@/features/projects/types';
import { axiosAdminUserAccount } from '@/lib/axiosAdminUserAccount';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { useQuery } from '@tanstack/react-query';

type AllPermissionType = Record<string, { project_area: string; registry_id?: string; region?: string }[]>;

const getAllUserPermissions = async (): Promise<AllPermissionType> => {
  const resp = await axiosAdminUserAccount.post<AllPermissionType>(
    `/api/v/1/code/${IoTCoreAdminSystemInfo.get().systemKey}/getAllUserPermissions`,
  );
  return resp.data;
};

export const getAllUserPermsQueryKeys = {
  all: () => [{ scope: 'getAllUserPermissions' }] as const,
};

export const useAllUserPermissions = () => {
  return useQuery(getAllUserPermsQueryKeys.all(), getAllUserPermissions, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useUserHavePermForResource = ({ perm, resources }: { perm: string; resources: Resource[] }): boolean => {
  // Look for specified perm within the list of all of the user's perms. Only accept perms from specified resources.
  const { data } = useAllUserPermissions();
  if (!data) return false;
  const perms = data[perm];
  if (!perms || !resources.length) return false;
  // If perm is present for any of the included resources, return true
  return (
    perms.filter((p) =>
      resources.find((r) => {
        if (r.type === 'registry') {
          return r.project === p.project_area && r.registryID === p.registry_id && r.region === p.region;
        }
        return r.project === p.project_area && !p.registry_id && !p.region;
      }),
    ).length > 0
  );
};
