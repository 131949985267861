import { ProtectedRouteIndex } from '@/routes/config';
import { lazyImport } from '@/utils/lazyImport';
import { Navigate, Route, Routes } from 'react-router-dom';

const { ProjectsIndex } = lazyImport(() => import('../components/ProjectsIndex'), 'ProjectsIndex');
const { ProjectsNew } = lazyImport(() => import('../components/ProjectsNew'), 'ProjectsNew');
const { ProjectsLayout } = lazyImport(() => import('../components/ProjectsLayout'), 'ProjectsLayout');
const { ProjectsEdit } = lazyImport(() => import('../components/ProjectsEdit'), 'ProjectsEdit');
const { ProjectAreaAccountCredentials } = lazyImport(
  () => import('../components/ProjectAreaAccountCredentials'),
  'ProjectAreaAccountCredentials',
);
const { ProjectAreaPrincipalManagement } = lazyImport(
  () => import('../components/ProjectAreaPrincipalManagement'),
  'ProjectAreaPrincipalManagement',
);

export const ProjectsRoutes = () => {
  return (
    <Routes>
      <Route path={ProjectRoutePaths.index} element={<ProjectsLayout />}>
        <Route index element={<ProjectsIndex />} />

        <Route path={`:${ProjectRoutePaths.project}/${ProjectRoutePaths.edit}`} element={<ProjectsEdit />}>
          <Route index element={<Navigate to={ProjectRoutePaths.accountCredentials} />} />
          <Route path={ProjectRoutePaths.accountCredentials} element={<ProjectAreaAccountCredentials />} />
          <Route path={ProjectRoutePaths.principalManagement} element={<ProjectAreaPrincipalManagement />} />
        </Route>
      </Route>
      <Route path={ProjectRoutePaths.new} element={<ProjectsNew />} />
    </Routes>
  );
};

export const projectPathResolvers = {
  projects: () => `/${ProtectedRouteIndex}/${ProjectRoutePaths.index}`,
  projectNew: () => `/${ProtectedRouteIndex}/${ProjectRoutePaths.new}`,
  projectEdit: (params: { projectName: string }) =>
    `${projectPathResolvers.projects()}/${params.projectName}/${ProjectRoutePaths.edit}`,
  projectAccountCredentials: (params: { projectName: string }) =>
    `${projectPathResolvers.projects()}/${params.projectName}/${ProjectRoutePaths.edit}/${
      ProjectRoutePaths.accountCredentials
    }`,
  projectPrincipalManagement: (params: { projectName: string }) =>
    `${projectPathResolvers.projects()}/${params.projectName}/${ProjectRoutePaths.edit}/${
      ProjectRoutePaths.principalManagement
    }`,
};

const ProjectRoutePaths = {
  index: 'projects' as const,
  new: 'project-new' as const,
  edit: 'edit' as const,
  accountCredentials: 'account-credentials' as const,
  principalManagement: 'principal-management' as const,
  project: 'projectName' as const,
};
