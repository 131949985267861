import { axiosAdminNoAuth } from '@/lib/axiosAdminNoAuth';
import { IoTCoreAdminSystemInfo } from '@/providers';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export type GetResetRequestParams = {
  pending_reset_id?: string;
  password: string;
};

type ResetUserResponse = {
  value: {
    email: string;
  };
};

export const resetPassword = async (data: GetResetRequestParams): Promise<ResetUserResponse> => {
  const resp = await axiosAdminNoAuth.post(
    `/api/v/4/webhook/execute/${IoTCoreAdminSystemInfo.get().systemKey}/resetPassword`,
    data,
  );
  return resp.data;
};

export const resetPasswordQueryKeys = {
  all: (params: { email: string; baseUrl: string }) => [{ scope: 'resetPassword', params }] as const,
};

export const useResetPassword = <TContext>(
  options?: UseMutationOptions<ResetUserResponse, unknown, GetResetRequestParams, TContext>,
) => {
  return useMutation((payload: GetResetRequestParams) => resetPassword(payload), options);
};
