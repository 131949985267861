import { IoTCoreAdminSystemInfo } from '@/providers';
import { AxiosRequestConfig } from 'axios';

export function baseUrlRequestInterceptor(config: AxiosRequestConfig) {
  const envInfo = IoTCoreAdminSystemInfo.get();

  config.baseURL = envInfo.URL;

  return config;
}
